// Generated by ReScript, PLEASE EDIT WITH CARE

import HtmlReactParser from "html-react-parser";

function outputToString(output) {
  return output._0;
}

function outputToReactElement(output) {
  switch (output.TAG | 0) {
    case /* TextPlain */0 :
        var text = output._0;
        if (text !== "") {
          return text;
        } else {
          return null;
        }
    case /* TextHTML */1 :
        return HtmlReactParser(output._0);
    case /* ImagePNG */2 :
        return output._0;
    
  }
}

function cellReducer(state, action) {
  if (typeof action === "number") {
    if (action === /* ResetCellOutput */0) {
      state.outputs.contents = [{
          TAG: /* TextPlain */0,
          _0: ""
        }];
      state.display_input.contents = "inline-block";
      return {
              cell_type: state.cell_type,
              source: state.source,
              outputs: state.outputs,
              display_input: state.display_input
            };
    }
    state.display_input.contents = "none";
    return {
            cell_type: state.cell_type,
            source: state.source,
            outputs: state.outputs,
            display_input: state.display_input
          };
  } else {
    if (action.TAG === /* ChangeCellText */0) {
      state.source.contents = action._0;
      return {
              cell_type: state.cell_type,
              source: state.source,
              outputs: state.outputs,
              display_input: state.display_input
            };
    }
    state.outputs.contents = action._0;
    state.display_input.contents = action._1;
    return {
            cell_type: state.cell_type,
            source: state.source,
            outputs: state.outputs,
            display_input: state.display_input
          };
  }
}

export {
  outputToString ,
  outputToReactElement ,
  cellReducer ,
  
}
/* html-react-parser Not a pure module */
