// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Theme$Weblab from "../service/Theme.bs.js";
import * as Errors$Weblab from "../service/Errors.bs.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";
import * as NotebookBase$Weblab from "./NotebookBase.bs.js";
import * as NotebookFormat$Weblab from "../service/NotebookFormat.bs.js";
import Save from "@material-ui/icons/Save";
import * as Styles$1 from "@material-ui/core/styles";
import Clear from "@material-ui/icons/Clear";
import * as WeblabInterpreter$Weblab from "../bindings/WeblabInterpreter.bs.js";
import Replay from "@material-ui/icons/Replay";
import DoubleArrow from "@material-ui/icons/DoubleArrow";

var transOri = {
  horizontal: 0,
  vertical: -36
};

var useStyles = Styles.makeStyles({
      buttonPadding: {
        paddingRight: "16px",
        paddingLeft: "16px"
      }
    });

function Menu(Props) {
  var notebookState = Props.notebookState;
  var notebookDispatch = Props.notebookDispatch;
  var filesDispatch = Props.filesDispatch;
  var match = React.useState(function () {
        
      });
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var menuNumber = React.useRef(0);
  var handleClick = function (number, $$event) {
    var target = $$event.currentTarget;
    Curry._1(setAnchorEl, (function (_x) {
            return Caml_option.some(target);
          }));
    menuNumber.current = number;
    
  };
  var handleClose = function (_event, _reason) {
    return Curry._1(setAnchorEl, (function (_x) {
                  
                }));
  };
  var theme = Styles$1.useTheme();
  var darkMode = Theme$Weblab.getMode(theme);
  var classes = useStyles();
  return React.createElement(Core.Box, {
              children: null,
              flexDirection: "row",
              justifyItems: "center"
            }, React.createElement(Core.Tooltip, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            return handleClick(0, param);
                          }),
                        style: {
                          textTransform: "none"
                        },
                        children: React.createElement(Core.Typography, {
                              children: "File"
                            }),
                        classes: {
                          root: classes.buttonPadding
                        },
                        color: darkMode ? "default" : "primary",
                        size: "small"
                      }),
                  title: "File"
                }), React.createElement(Core.Menu, {
                  keepMounted: true,
                  transformOrigin: transOri,
                  anchorEl: anchorEl,
                  children: React.createElement(Core.MenuItem, {
                        children: null,
                        dense: true,
                        onClick: (function (evt) {
                            Curry._1(setAnchorEl, (function (_x) {
                                    
                                  }));
                            return Curry._1(filesDispatch, {
                                        TAG: /* AddFile */0,
                                        _0: notebookState.name,
                                        _1: {
                                          TAG: /* Notebook */0,
                                          _0: NotebookFormat$Weblab.notebookCopytoString(notebookState.indices, notebookState.cells)
                                        }
                                      });
                          })
                      }, React.createElement(Core.ListItemIcon, {
                            children: React.createElement(Save, {
                                  fontSize: "small"
                                })
                          }), React.createElement(Core.ListItemText, {
                            children: "Save"
                          })),
                  MenuListProps: {
                    dense: true,
                    disablePadding: true
                  },
                  onClose: handleClose,
                  open: Belt_Option.isSome(anchorEl) && menuNumber.current === 0,
                  transitionDuration: 0.2,
                  variant: "menu"
                }), React.createElement(Core.Tooltip, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            return handleClick(1, param);
                          }),
                        style: {
                          textTransform: "none"
                        },
                        children: React.createElement(Core.Typography, {
                              children: "Cells"
                            }),
                        classes: {
                          root: classes.buttonPadding
                        },
                        color: darkMode ? "default" : "primary",
                        size: "small"
                      }),
                  title: "Cells"
                }), React.createElement(Core.Menu, {
                  keepMounted: true,
                  transformOrigin: transOri,
                  anchorEl: anchorEl,
                  children: null,
                  MenuListProps: {
                    dense: true,
                    disablePadding: true
                  },
                  onClose: handleClose,
                  open: Belt_Option.isSome(anchorEl) && menuNumber.current === 1,
                  transitionDuration: 0.2,
                  variant: "menu"
                }, React.createElement(Core.MenuItem, {
                      children: null,
                      dense: true,
                      onClick: (function (evt) {
                          Curry._1(setAnchorEl, (function (_x) {
                                  
                                }));
                          return Belt_List.forEach(notebookState.indices, (function (i) {
                                        return Belt_Option.map(Belt_HashMapInt.get(notebookState.cells, i), (function (cellState) {
                                                      NotebookBase$Weblab.evalCell(cellState, notebookDispatch);
                                                      
                                                    }));
                                      }));
                        })
                    }, React.createElement(Core.ListItemIcon, {
                          children: React.createElement(DoubleArrow, {
                                fontSize: "small"
                              })
                        }), React.createElement(Core.ListItemText, {
                          children: "Run all cells"
                        })), React.createElement(Core.MenuItem, {
                      children: null,
                      dense: true,
                      onClick: (function (evt) {
                          Curry._1(setAnchorEl, (function (_x) {
                                  
                                }));
                          return Curry._1(notebookDispatch, /* ClearAllCodeOutput */0);
                        })
                    }, React.createElement(Core.ListItemIcon, {
                          children: React.createElement(Clear, {
                                fontSize: "small"
                              })
                        }), React.createElement(Core.ListItemText, {
                          children: "Clear all code outputs"
                        }))), React.createElement(Core.Tooltip, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (param) {
                            return handleClick(2, param);
                          }),
                        style: {
                          textTransform: "none"
                        },
                        children: React.createElement(Core.Typography, {
                              children: "Runtime"
                            }),
                        classes: {
                          root: classes.buttonPadding
                        },
                        color: darkMode ? "default" : "primary",
                        size: "small"
                      }),
                  title: "Runtime"
                }), React.createElement(Core.Menu, {
                  keepMounted: true,
                  transformOrigin: transOri,
                  anchorEl: anchorEl,
                  children: null,
                  MenuListProps: {
                    dense: true,
                    disablePadding: true
                  },
                  onClose: handleClose,
                  open: Belt_Option.isSome(anchorEl) && menuNumber.current === 2,
                  transitionDuration: 0.2,
                  variant: "menu"
                }, React.createElement(Core.MenuItem, {
                      children: null,
                      dense: true,
                      onClick: (function (evt) {
                          Curry._1(setAnchorEl, (function (_x) {
                                  
                                }));
                          WeblabInterpreter$Weblab.resetEnvs(undefined).catch(function (error) {
                                Errors$Weblab.alertError({
                                      TAG: /* Error */1,
                                      _0: Errors$Weblab.fromPromiseError(error)
                                    });
                                return Promise.resolve(undefined);
                              });
                          
                        })
                    }, React.createElement(Core.ListItemIcon, {
                          children: React.createElement(Replay, {
                                fontSize: "small"
                              })
                        }), React.createElement(Core.ListItemText, {
                          children: "Reset runtime"
                        })), React.createElement(Core.MenuItem, {
                      children: null,
                      dense: true,
                      onClick: (function (evt) {
                          Curry._1(setAnchorEl, (function (_x) {
                                  
                                }));
                          WeblabInterpreter$Weblab.resetEnvs(undefined).then(function (param) {
                                  Curry._1(notebookDispatch, /* ClearAllCodeOutput */0);
                                  return Promise.resolve(undefined);
                                }).catch(function (error) {
                                Errors$Weblab.alertError({
                                      TAG: /* Error */1,
                                      _0: Errors$Weblab.fromPromiseError(error)
                                    });
                                return Promise.resolve(undefined);
                              });
                          
                        })
                    }, React.createElement(Core.ListItemIcon, {
                          children: React.createElement(Clear, {
                                fontSize: "small"
                              })
                        }), React.createElement(Core.ListItemText, {
                          children: "Reset runtime & code outputs"
                        }))));
}

var make = Menu;

export {
  transOri ,
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
