// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Language from "@codemirror/language";
import * as WeblabInterpreter$Weblab from "../WeblabInterpreter.bs.js";

var completePropertyAfter = [
  "PropertyName",
  ".",
  "?."
];

var dontCompleteIn = [
  "TemplateString",
  "LineComment",
  "BlockComment",
  "VariableDefinition",
  "PropertyDefinition"
];

function completeProperties(from, object) {
  var options = [];
  Js_dict.entries(object).map(function (tuple) {
        return options.push({
                    label: tuple[0],
                    type: tuple[1] === "function" ? "function" : "variable"
                  });
      });
  return {
          from: from,
          options: options,
          span: /^[\w$]*$/
        };
}

function completeFromGlobalScope(context) {
  var nodeBefore = Language.syntaxTree(context.state).resolveInner(context.pos, -1);
  if (!(completePropertyAfter.includes(nodeBefore.name) && nodeBefore.parent.name === "MemberExpression")) {
    if (nodeBefore.name === "VariableName") {
      return WeblabInterpreter$Weblab.listProperties("").then(function (props) {
                  return Promise.resolve(completeProperties(nodeBefore.from, props));
                });
    } else if (context.explicit && !dontCompleteIn.includes(nodeBefore.name)) {
      return WeblabInterpreter$Weblab.listProperties("").then(function (props) {
                  return Promise.resolve(completeProperties(context.pos, props));
                });
    } else {
      return Promise.resolve(completeProperties(context.pos, {}));
    }
  }
  var object = nodeBefore.parent.getChild("Expression");
  if (object.name !== "VariableName") {
    return Promise.resolve(completeProperties(context.pos, {}));
  }
  var from = /\./.test(nodeBefore.name) ? nodeBefore.to : nodeBefore.from;
  var variableName = context.state.sliceDoc(object.from, object.to);
  return WeblabInterpreter$Weblab.getType(variableName).then(function (type_) {
                if (type_ === "object") {
                  return WeblabInterpreter$Weblab.listProperties(variableName);
                } else {
                  return Promise.resolve({});
                }
              }).then(function (props) {
              return Promise.resolve(completeProperties(from, props));
            });
}

export {
  completePropertyAfter ,
  dontCompleteIn ,
  completeProperties ,
  completeFromGlobalScope ,
  
}
/* @codemirror/language Not a pure module */
