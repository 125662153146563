// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Fold from "@codemirror/fold";
import * as Lint from "@codemirror/lint";
import * as View from "@codemirror/view";
import * as State from "@codemirror/state";
import * as Search from "@codemirror/search";
import * as $$Comment from "@codemirror/comment";
import * as $$History from "@codemirror/history";
import * as AutoComplete$Weblab from "./AutoComplete.bs.js";
import * as Commands from "@codemirror/commands";
import * as Language from "@codemirror/language";
import * as Highlight from "@codemirror/highlight";
import * as Autocomplete from "@codemirror/autocomplete";
import * as Closebrackets from "@codemirror/closebrackets";
import * as LangMarkdown from "@codemirror/lang-markdown";
import * as Matchbrackets from "@codemirror/matchbrackets";
import * as ThemeOneDark from "@codemirror/theme-one-dark";
import * as LangJavascript from "@codemirror/lang-javascript";
import * as RectangularSelection from "@codemirror/rectangular-selection";

function CodeMirror$MyCodeMirror(Props) {
  var component = Props.component;
  var inputRef = Props.inputRef;
  var source = Props.source;
  var cell_type = Props.cell_type;
  var cellDispatch = Props.cellDispatch;
  var onKeyDown = Props.onKeyDown;
  var onFocus = Props.onFocus;
  var className = Props.className;
  var onBlur = Props.onBlur;
  var onChange = Props.onChange;
  var darkMode = Props.darkMode;
  var ref = React.useRef(null);
  var setRef = function (element) {
    ref.current = element;
    
  };
  React.useImperativeHandle(inputRef, (function () {
          return {
                  focus: (function (param) {
                      return Belt_Option.forEach(Caml_option.nullable_to_opt(ref.current), (function (x) {
                                    x.focus();
                                    
                                  }));
                    }),
                  blur: (function (param) {
                      return Belt_Option.forEach(Caml_option.nullable_to_opt(ref.current), (function (x) {
                                    x.blur();
                                    
                                  }));
                    })
                };
        }), []);
  var props = {
    inputRef: ref,
    setInputRef: setRef,
    source: source,
    cell_type: cell_type,
    cellDispatch: cellDispatch,
    onKeyDown: onKeyDown,
    onFocus: onFocus,
    className: className,
    onBlur: onBlur,
    onChange: onChange,
    darkMode: darkMode
  };
  return React.createElement(component, props);
}

var MyCodeMirror = {
  make: CodeMirror$MyCodeMirror
};

function CodeMirror(Props) {
  var inputRef = Props.inputRef;
  var setInputRef = Props.setInputRef;
  var source = Props.source;
  var cell_type = Props.cell_type;
  var cellDispatch = Props.cellDispatch;
  var onKeyDown = Props.onKeyDown;
  var onFocus = Props.onFocus;
  var className = Props.className;
  var onBlur = Props.onBlur;
  var onChange = Props.onChange;
  var darkMode = Props.darkMode;
  React.useEffect((function () {
          var onUpdate = function (param) {
            return View.EditorView.updateListener.of(function (v) {
                        var value = v.state.doc.toString();
                        if (value !== source.contents) {
                          return Curry._1(cellDispatch, {
                                      TAG: /* ChangeCellText */0,
                                      _0: value
                                    });
                        }
                        
                      });
          };
          var globalJavaScriptCompletions = LangJavascript.javascriptLanguage.data.of({
                autocomplete: AutoComplete$Weblab.completeFromGlobalScope
              });
          var view = new View.EditorView({
                state: State.EditorState.create({
                      doc: source.contents,
                      extensions: [
                        View.highlightSpecialChars(),
                        $$History.history(),
                        View.drawSelection(),
                        State.EditorState.allowMultipleSelections.of(true),
                        Language.indentOnInput(),
                        View.EditorView.lineWrapping,
                        darkMode ? ThemeOneDark.oneDarkHighlightStyle.fallback : Highlight.defaultHighlightStyle.fallback,
                        Matchbrackets.bracketMatching(),
                        Closebrackets.closeBrackets(),
                        Autocomplete.autocompletion(),
                        RectangularSelection.rectangularSelection(),
                        Search.highlightSelectionMatches(),
                        View.keymap.of(Commands.defaultKeymap.concat(Closebrackets.closeBracketsKeymap, Search.searchKeymap, $$History.historyKeymap, Fold.foldKeymap, $$Comment.commentKeymap, Autocomplete.completionKeymap, Lint.lintKeymap, Commands.indentWithTab)),
                        View.EditorView.theme({
                              "&.cm-editor.cm-focused": {
                                outline: "none"
                              }
                            }),
                        cell_type ? LangMarkdown.markdown() : LangJavascript.javascript(),
                        globalJavaScriptCompletions,
                        onUpdate(undefined)
                      ]
                    }),
                parent: inputRef.current
              });
          return (function (param) {
                    return view.destroy();
                  });
        }), [darkMode]);
  return React.createElement("div", {
              ref: setInputRef,
              className: className,
              style: {
                width: "100%"
              },
              onKeyDown: onKeyDown,
              onFocus: onFocus,
              onBlur: onBlur,
              onChange: onChange
            });
}

var make = CodeMirror;

export {
  MyCodeMirror ,
  make ,
  
}
/* react Not a pure module */
