// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Theme$Weblab from "../../service/Theme.bs.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as FileItem$Weblab from "./FileItem.bs.js";
import * as FilesBase$Weblab from "../FilesBase.bs.js";
import * as Core from "@material-ui/core";
import * as Belt_HashSetString from "rescript/lib/es6/belt_HashSetString.js";
import * as NotebookBase$Weblab from "../NotebookBase.bs.js";
import * as NotebookFormat$Weblab from "../../service/NotebookFormat.bs.js";
import * as Sidepane_Header$Weblab from "./Sidepane_Header.bs.js";
import * as Styles from "@material-ui/core/styles";
import NoteAdd from "@material-ui/icons/NoteAdd";
import Publish from "@material-ui/icons/Publish";

function Sidepane_Files(Props) {
  var toggle_sidebar = Props.toggle_sidebar;
  var filesState = Props.filesState;
  var filesDispatch = Props.filesDispatch;
  var notebookName = Props.notebookName;
  var notebookDispatch = Props.notebookDispatch;
  var theme = Styles.useTheme();
  var darkMode = Theme$Weblab.getMode(theme);
  return React.createElement(React.Fragment, undefined, React.createElement(Sidepane_Header$Weblab.make, {
                  toggle_sidebar: toggle_sidebar,
                  children: "Local Files"
                }), React.createElement(Core.Divider, {}), React.createElement(Core.Box, {
                  children: null
                }, React.createElement(Core.Tooltip, {
                      children: React.createElement(Core.Button, {
                            onClick: (function (evt_) {
                                var name = "";
                                if (Belt_HashSetString.has(filesState.files, "untitled.ijsnb")) {
                                  var i = 1;
                                  while(Belt_HashSetString.has(filesState.files, "untitled" + String(i) + ".ijsnb")) {
                                    i = i + 1 | 0;
                                  };
                                  name = "untitled" + String(i) + ".ijsnb";
                                } else {
                                  name = "untitled.ijsnb";
                                }
                                var notebook = Belt_Array.make(1, NotebookBase$Weblab.defaultCell(undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                                var indices = Belt_List.fromArray(Belt_Array.mapWithIndex(notebook, (function (i, param) {
                                            return i;
                                          })));
                                var cells = Belt_HashMapInt.fromArray(Belt_Array.mapWithIndex(notebook, (function (i, e) {
                                            return [
                                                    i,
                                                    e
                                                  ];
                                          })));
                                var file = NotebookFormat$Weblab.notebookCopytoString(indices, cells);
                                return Curry._1(filesDispatch, {
                                            TAG: /* AddFile */0,
                                            _0: name,
                                            _1: {
                                              TAG: /* Notebook */0,
                                              _0: file
                                            }
                                          });
                              }),
                            children: React.createElement(NoteAdd, {
                                  fontSize: "small"
                                }),
                            color: darkMode ? "default" : "primary",
                            size: "small"
                          }),
                      title: "New notebook"
                    }), React.createElement(Core.Tooltip, {
                      children: React.createElement(Core.Button, {
                            children: null,
                            color: darkMode ? "default" : "primary",
                            size: "small"
                          }, React.createElement(Core.InputLabel, {
                                style: {
                                  color: darkMode ? theme.palette.text.primary : theme.palette.primary.main
                                },
                                children: React.createElement(Publish, {
                                      fontSize: "small"
                                    }),
                                htmlFor: "openNotebook"
                              }), React.createElement(Core.Input, {
                                style: {
                                  display: "none"
                                },
                                id: "openNotebook",
                                inputProps: {
                                  accept: "application/.ijsnb"
                                },
                                onChange: (function (evt) {
                                    evt.preventDefault();
                                    var file = Caml_array.get(evt.target.files, 0);
                                    FilesBase$Weblab.getFileType(file).then(function (x) {
                                            Curry._1(filesDispatch, {
                                                  TAG: /* AddFile */0,
                                                  _0: file.name,
                                                  _1: x
                                                });
                                            return Promise.resolve(undefined);
                                          }).catch(function (x) {
                                          alert(x);
                                          return Promise.reject(Js_exn.anyToExnInternal("Failed to add file."));
                                        });
                                    
                                  }),
                                type: "file"
                              })),
                      title: "Upload file"
                    })), React.createElement(Core.Divider, {
                  style: {
                    marginBottom: "8px"
                  }
                }), React.createElement(Core.Box, {
                  children: React.createElement(Core.List, {
                        children: Belt_Array.map(Belt_HashSetString.toArray(filesState.files), (function (name) {
                                return React.createElement(FileItem$Weblab.make, {
                                            name: name,
                                            notebookName: notebookName,
                                            filesState: filesState,
                                            filesDispatch: filesDispatch,
                                            notebookDispatch: notebookDispatch,
                                            key: "fi" + name
                                          });
                              })),
                        dense: true
                      }),
                  border: 1,
                  borderColor: theme.palette.action.disabledBackground,
                  borderRadius: 8,
                  boxShadow: "inset 0 0 4px " + theme.palette.action.disabledBackground,
                  height: "100rem",
                  marginBottom: 1,
                  marginLeft: 1,
                  marginRight: 1
                }));
}

var make = Sidepane_Files;

export {
  make ,
  
}
/* react Not a pure module */
