// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";

function Dashboard(Props) {
  var children = Props.children;
  var theme = Styles.useTheme();
  return React.createElement(Core.Container, {
              children: React.createElement(Core.Box, {
                    style: {
                      backgroundColor: theme.palette.background.default,
                      minHeight: "100vh",
                      padding: "2rem"
                    },
                    children: React.createElement(Core.List, {
                          children: children
                        }),
                    borderRadius: 4,
                    boxShadow: 2
                  }),
              style: {
                paddingTop: "8px",
                zIndex: "20"
              }
            });
}

var make = Dashboard;

export {
  make ,
  
}
/* react Not a pure module */
