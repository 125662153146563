// Generated by ReScript, PLEASE EDIT WITH CARE


function reducer(state, action) {
  if (typeof action === "number") {
    if (action !== 0) {
      return {
              name: state.name,
              change: false
            };
    } else {
      return {
              name: state.name,
              change: true
            };
    }
  } else {
    return {
            name: action._0,
            change: state.change
          };
  }
}

export {
  reducer ,
  
}
/* No side effect */
