// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Theme$Weblab from "../../service/Theme.bs.js";
import * as Core from "@material-ui/core";
import * as Sidepane_Header$Weblab from "./Sidepane_Header.bs.js";
import * as Styles from "@material-ui/core/styles";
import * as GatsbyThemeMaterialUi from "gatsby-theme-material-ui";

function Sidepane_Documentation(Props) {
  var toggle_sidebar = Props.toggle_sidebar;
  var theme = Styles.useTheme();
  var darkMode = Theme$Weblab.getMode(theme);
  return React.createElement(React.Fragment, undefined, React.createElement(Sidepane_Header$Weblab.make, {
                  toggle_sidebar: toggle_sidebar,
                  children: React.createElement(GatsbyThemeMaterialUi.Link, {
                        to: "/documentation",
                        children: "Documentation",
                        style: {
                          color: darkMode ? theme.palette.text.primary : theme.palette.primary.main
                        }
                      })
                }), React.createElement(Core.Divider, {}), React.createElement(Core.List, {
                  children: null,
                  component: "nav"
                }, React.createElement(Core.ListItem, {
                      button: true,
                      children: React.createElement(Core.ListItemText, {
                            children: React.createElement(Core.Typography, {
                                  children: React.createElement(GatsbyThemeMaterialUi.Link, {
                                        to: "/documentation/getting_started",
                                        children: "Getting started",
                                        style: {
                                          color: darkMode ? theme.palette.text.primary : theme.palette.primary.main
                                        }
                                      })
                                })
                          })
                    }), React.createElement(Core.ListItem, {
                      button: true,
                      children: React.createElement(Core.ListItemText, {
                            children: React.createElement(Core.Typography, {
                                  children: React.createElement(GatsbyThemeMaterialUi.Link, {
                                        to: "/documentation/guides_and_tutorials",
                                        children: "Guides and Tutorials",
                                        style: {
                                          color: darkMode ? theme.palette.text.primary : theme.palette.primary.main
                                        }
                                      })
                                })
                          })
                    }), React.createElement(Core.ListItem, {
                      button: true,
                      children: React.createElement(Core.ListItemText, {
                            children: React.createElement(Core.Typography, {
                                  children: React.createElement(GatsbyThemeMaterialUi.Link, {
                                        to: "/documentation/interface",
                                        children: "The Weblab interface",
                                        style: {
                                          color: darkMode ? theme.palette.text.primary : theme.palette.primary.main
                                        }
                                      })
                                })
                          })
                    }), React.createElement(Core.ListItem, {
                      button: true,
                      children: React.createElement(Core.ListItemText, {
                            children: React.createElement(Core.Typography, {
                                  children: React.createElement(GatsbyThemeMaterialUi.Link, {
                                        to: "/documentation/javascript",
                                        children: "Javascript",
                                        style: {
                                          color: darkMode ? theme.palette.text.primary : theme.palette.primary.main
                                        }
                                      })
                                })
                          })
                    })));
}

var make = Sidepane_Documentation;

export {
  make ,
  
}
/* react Not a pure module */
