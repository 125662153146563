// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Cell$Weblab from "./Cell.bs.js";
import * as Menu$Weblab from "./Menu.bs.js";
import * as Login$Weblab from "./Login.bs.js";
import * as Theme$Weblab from "../service/Theme.bs.js";
import * as ReactHelmet from "react-helmet";
import * as Topbar$Weblab from "./Topbar.bs.js";
import * as Session$Weblab from "../service/Session.bs.js";
import * as Sidebar$Weblab from "./Sidebar.bs.js";
import * as Belt_HashMapInt from "rescript/lib/es6/belt_HashMapInt.js";
import * as Sidepane$Weblab from "./sidepane/Sidepane.bs.js";
import * as Dashboard$Weblab from "./Dashboard.bs.js";
import * as FilesBase$Weblab from "./FilesBase.bs.js";
import * as Core from "@material-ui/core";
import * as Belt_HashSetString from "rescript/lib/es6/belt_HashSetString.js";
import * as NotebookBase$Weblab from "./NotebookBase.bs.js";
import * as SidebarsBase$Weblab from "./SidebarsBase.bs.js";
import * as NotebookFormat$Weblab from "../service/NotebookFormat.bs.js";
import * as Sidepane_Files$Weblab from "./sidepane/Sidepane_Files.bs.js";
import * as SupabaseClient$Weblab from "../bindings/supabase/SupabaseClient.bs.js";
import * as Styles from "@material-ui/core/styles";
import * as Sidepane_Settings$Weblab from "./sidepane/Sidepane_Settings.bs.js";
import Folder from "@material-ui/icons/Folder";
import MenuBook from "@material-ui/icons/MenuBook";
import Settings from "@material-ui/icons/Settings";
import * as Sidepane_Documentation$Weblab from "./sidepane/Sidepane_Documentation.bs.js";

var initialFiles = Belt_HashSetString.make(10);

var titleSEO = {
  contents: "Dashbook - Big Data Platform"
};

function Notebook(Props) {
  var $$location = Props.location;
  var name = Props.name;
  var initialIndices = Props.initialIndices;
  var initialCells = Props.initialCells;
  var match = React.useReducer(NotebookBase$Weblab.notebookReducer, {
        name: name,
        count: Belt_List.length(initialIndices),
        indices: initialIndices,
        cells: initialCells
      });
  var notebookDispatch = match[1];
  var notebookState = match[0];
  var selectedCell = React.useRef(undefined);
  var match$1 = React.useReducer(FilesBase$Weblab.filesReducer, {
        files: initialFiles
      });
  var filesDispatch = match$1[1];
  var filesState = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSession = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setLoginDialog = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setDarkMode = match$4[1];
  var darkMode = match$4[0];
  var match$5 = React.useState(function () {
        return "0";
      });
  var setActiveTab = match$5[1];
  var activeTab = match$5[0];
  var toggle_active_tab = React.useCallback((function (tab) {
          if (activeTab !== tab) {
            return Curry._1(setActiveTab, (function (_x) {
                          return tab;
                        }));
          }
          
        }), [
        activeTab,
        setActiveTab
      ]);
  var classes = Curry._1(Theme$Weblab.Styles.useStyles, undefined);
  React.useEffect((function () {
          Curry._1(setDarkMode, (function (param) {
                  return Theme$Weblab.initializeDarkMode(undefined);
                }));
          if ($$location.search !== "") {
            var url = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(new URLSearchParams($$location.search).get("url")), "");
            fetch(url).then(function (x) {
                        return x.text();
                      }).then(function (text) {
                      return NotebookFormat$Weblab.convertNotebookJSONtoRE(JSON.parse(decodeURIComponent(text)));
                    }).then(function (x) {
                    var oldName = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(url.split("/").pop()), "notebook.ijsnb");
                    var name = Belt_Option.isSome(Caml_option.nullable_to_opt(window.localStorage.getItem(oldName))) ? Belt_Option.getWithDefault(Caml_option.nullable_to_opt(prompt("A notebook with that name exists already. Please rename the notebook.", oldName)), oldName) : oldName;
                    var welcomeNotebook = Belt_Result.getWithDefault(x, Belt_Array.make(1, NotebookBase$Weblab.defaultCell(undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
                    var initialIndices = Belt_List.fromArray(Belt_Array.mapWithIndex(welcomeNotebook, (function (i, param) {
                                return i;
                              })));
                    var initialCells = Belt_HashMapInt.fromArray(Belt_Array.mapWithIndex(welcomeNotebook, (function (i, e) {
                                return [
                                        i,
                                        e
                                      ];
                              })));
                    Curry._1(filesDispatch, {
                          TAG: /* Setup */1,
                          _0: name,
                          _1: {
                            TAG: /* Notebook */0,
                            _0: NotebookFormat$Weblab.notebookCopytoString(initialIndices, initialCells)
                          }
                        });
                    Curry._1(notebookDispatch, {
                          TAG: /* OpenNotebook */5,
                          _0: {
                            name: name,
                            count: welcomeNotebook.length,
                            indices: initialIndices,
                            cells: initialCells
                          }
                        });
                    return Promise.resolve(undefined);
                  }).catch(function (x) {
                  alert("Error: Failed to load notebook from url.");
                  return Promise.reject(Js_exn.anyToExnInternal(x));
                });
          } else {
            Curry._1(filesDispatch, {
                  TAG: /* Setup */1,
                  _0: name,
                  _1: {
                    TAG: /* Notebook */0,
                    _0: NotebookFormat$Weblab.notebookCopytoString(notebookState.indices, notebookState.cells)
                  }
                });
          }
          Curry._1(setSession, (function (param) {
                  return Caml_option.nullable_to_opt(SupabaseClient$Weblab.supabase.auth.session());
                }));
          SupabaseClient$Weblab.supabase.auth.onAuthStateChange(function (param, session) {
                return Curry._1(setSession, (function (param) {
                              if (session == null) {
                                return ;
                              } else {
                                return Caml_option.some(session);
                              }
                            }));
              });
          titleSEO.contents = undefined;
          
        }), []);
  var theme = React.useMemo((function () {
          return Styles.createTheme(Theme$Weblab.getThemeProto(darkMode));
        }), [darkMode]);
  var topbar = React.useMemo((function () {
          return React.createElement(Topbar$Weblab.make, {
                      setLoginDialog: setLoginDialog
                    });
        }), []);
  var sidebar = React.useMemo((function () {
          return React.createElement(Sidebar$Weblab.make, {
                      toggle_sidebar: SidebarsBase$Weblab.toggle_sidebar,
                      activeTab: activeTab,
                      toggle_active_tab: toggle_active_tab,
                      children: null
                    }, React.createElement(Core.Tooltip, {
                          children: React.createElement(Folder, {}),
                          title: "Files"
                        }), React.createElement(Core.Tooltip, {
                          children: React.createElement(MenuBook, {}),
                          title: "Documentation"
                        }), React.createElement(Core.Tooltip, {
                          children: React.createElement(Settings, {}),
                          title: "Settings"
                        }));
        }), [
        activeTab,
        toggle_active_tab
      ]);
  var sidepane = React.useMemo((function () {
          return React.createElement(Sidepane$Weblab.make, {
                      activeTab: activeTab,
                      children: null
                    }, React.createElement(Sidepane_Files$Weblab.make, {
                          toggle_sidebar: SidebarsBase$Weblab.toggle_sidebar,
                          filesState: filesState,
                          filesDispatch: filesDispatch,
                          notebookName: notebookState.name,
                          notebookDispatch: notebookDispatch
                        }), React.createElement(Sidepane_Documentation$Weblab.make, {
                          toggle_sidebar: SidebarsBase$Weblab.toggle_sidebar
                        }), React.createElement(Sidepane_Settings$Weblab.make, {
                          toggle_sidebar: SidebarsBase$Weblab.toggle_sidebar,
                          darkMode: darkMode,
                          setDarkMode: setDarkMode
                        }));
        }), [
        activeTab,
        filesState,
        filesDispatch,
        notebookState,
        notebookDispatch,
        darkMode
      ]);
  var menu = React.useMemo((function () {
          return React.createElement(Menu$Weblab.make, {
                      notebookState: notebookState,
                      notebookDispatch: notebookDispatch,
                      filesDispatch: filesDispatch
                    });
        }), [
        notebookState,
        notebookDispatch,
        filesState,
        filesDispatch
      ]);
  var cells = React.useMemo((function () {
          return Belt_Array.map(Belt_Array.map(Belt_List.toArray(notebookState.indices), (function (i) {
                            return [
                                    i,
                                    Belt_Option.getWithDefault(Belt_HashMapInt.get(notebookState.cells, i), NotebookBase$Weblab.defaultCell(undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                                  ];
                          })), (function (x) {
                        return React.createElement(Cell$Weblab.make, {
                                    cellState: x[1],
                                    notebookDispatch: notebookDispatch,
                                    selectedCell: selectedCell,
                                    key: notebookState.name + "_" + String(x[0])
                                  });
                      }));
        }), [
        notebookState,
        notebookDispatch
      ]);
  return React.createElement(Styles.ThemeProvider, {
              children: React.createElement(Session$Weblab.SessionContext.Provider.make, {
                    value: match$2[0],
                    children: null
                  }, React.createElement(Core.CssBaseline, {}), React.createElement(ReactHelmet.Helmet, {
                        children: null
                      }, React.createElement("link", {
                            href: "favicon.png",
                            rel: "icon",
                            type: "image/png"
                          }), React.createElement("title", undefined, Belt_Option.getWithDefault(titleSEO.contents, "Weblab - " + notebookState.name)), React.createElement("meta", {
                            content: process.env.GOOGLE_SITE_VERIFICATION,
                            name: "google-site-verification"
                          }), React.createElement("script", {
                            type: "application/ld+json"
                          }, "{\n          \"@context\": \"https://schema.org\",\n          \"@type\": \"WebApplication\",\n          \"headline\": \"Run code and visualize data in an interactive notebook.\",\n          \"applicationCategory\": \"Machine Learning, Data Science\",\n          \"operatingSystem\": \"Windows, MacOS, Linux\",\n          \"image\": \"https://www.weblab.ai/landing1x1.svg\"\n        }")), React.createElement(Core.Box, {
                        className: classes.sidebar,
                        children: React.createElement("nav", undefined, sidebar),
                        bgcolor: theme.palette.background.default,
                        boxShadow: 2
                      }), React.createElement(Core.Box, {
                        className: classes.wrapper,
                        children: null,
                        gridTemplateColumns: {
                          xs: "48px 0px 1fr",
                          sm: "48px 0px 1fr",
                          md: "48px auto 1fr"
                        },
                        id: "wrapper"
                      }, React.createElement(Core.Box, {
                            className: classes.topbar,
                            children: topbar,
                            boxShadow: 2
                          }), React.createElement(Core.Box, {
                            className: classes.sidepane,
                            children: sidepane,
                            bgcolor: theme.palette.background.default,
                            boxShadow: 3
                          }), React.createElement(Core.Box, {
                            style: {
                              position: "sticky",
                              top: "0px"
                            },
                            children: menu,
                            bgcolor: theme.palette.background.default,
                            boxShadow: 2,
                            display: "flex",
                            gridColumn: "3",
                            gridRow: "2",
                            overflow: "auto",
                            zIndex: 30
                          }), React.createElement(Core.Box, {
                            children: React.createElement("main", undefined, React.createElement(Dashboard$Weblab.make, {
                                      children: cells
                                    })),
                            bgcolor: theme.palette.action.selected,
                            gridColumn: "3",
                            gridRow: "3"
                          })), React.createElement(Login$Weblab.make, {
                        loginDialog: match$3[0],
                        setLoginDialog: setLoginDialog
                      })),
              theme: theme
            });
}

Notebook.displayName = "Notebook";

var supabase = SupabaseClient$Weblab.supabase;

var make = Notebook;

var $$default = Notebook;

export {
  supabase ,
  initialFiles ,
  titleSEO ,
  make ,
  $$default ,
  $$default as default,
  
}
/* initialFiles Not a pure module */
