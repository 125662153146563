// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Theme$Weblab from "../../service/Theme.bs.js";
import * as Errors$Weblab from "../../service/Errors.bs.js";
import * as HubBase$Weblab from "../../pagesComponents/HubBase.bs.js";
import * as Session$Weblab from "../../service/Session.bs.js";
import * as FilesBase$Weblab from "../FilesBase.bs.js";
import * as Core from "@material-ui/core";
import * as FileItemBase$Weblab from "./FileItemBase.bs.js";
import * as Styles from "@material-ui/core/styles";
import Delete from "@material-ui/icons/Delete";
import SaveAlt from "@material-ui/icons/SaveAlt";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import OpenInNew from "@material-ui/icons/OpenInNew";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Description from "@material-ui/icons/Description";

var transOri = {
  horizontal: -50,
  vertical: 0
};

function FileItem(Props) {
  var name = Props.name;
  var notebookName = Props.notebookName;
  var filesState = Props.filesState;
  var filesDispatch = Props.filesDispatch;
  var notebookDispatch = Props.notebookDispatch;
  var match = React.useReducer(FileItemBase$Weblab.reducer, {
        name: name,
        change: false
      });
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setAnchorEl = match$1[1];
  var anchorEl = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setHover = match$2[1];
  var handleClick = function ($$event) {
    var target = $$event.currentTarget;
    return Curry._1(setAnchorEl, (function (_x) {
                  return Caml_option.some(target);
                }));
  };
  var handleClose = function (_event, _reason) {
    return Curry._1(setAnchorEl, (function (_x) {
                  
                }));
  };
  var session = React.useContext(Session$Weblab.SessionContext.context);
  var theme = Styles.useTheme();
  var darkMode = Theme$Weblab.getMode(theme);
  return React.createElement(Core.ListItem, {
              children: React.createElement(Core.Box, {
                    children: null,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    overflow: "hidden",
                    width: "100%"
                  }, React.createElement(Description, {
                        fontSize: "small"
                      }), state.change ? React.createElement(Core.TextField, {
                          InputProps: {
                            onKeyDown: (function (evt) {
                                if (evt.key === "Enter" || evt.key === "Escape") {
                                  Curry._1(dispatch, /* Send */1);
                                  if (name !== state.name) {
                                    Curry._1(filesDispatch, {
                                          TAG: /* ChangeName */3,
                                          _0: name,
                                          _1: state.name
                                        });
                                    if (state.name === notebookName) {
                                      return Curry._1(notebookDispatch, {
                                                  TAG: /* ChangeNotebookName */7,
                                                  _0: state.name
                                                });
                                    } else {
                                      return ;
                                    }
                                  } else {
                                    return ;
                                  }
                                }
                                
                              })
                          },
                          margin: "none",
                          onChange: (function (evt) {
                              return Curry._1(dispatch, /* ChangeName */{
                                          _0: evt.target.value
                                        });
                            }),
                          size: "small",
                          value: state.name,
                          variant: "outlined"
                        }) : React.createElement("div", {
                          style: {
                            width: "100%"
                          },
                          onMouseOut: (function (param) {
                              return Curry._1(setHover, (function (param) {
                                            
                                          }));
                            }),
                          onMouseOver: (function (param) {
                              return Curry._1(setHover, (function (param) {
                                            return Caml_option.some(undefined);
                                          }));
                            })
                        }, React.createElement(Core.Typography, {
                              children: React.createElement("div", {
                                    onDoubleClick: (function (param) {
                                        return Curry._1(dispatch, /* ShowTextfield */0);
                                      })
                                  }, name),
                              variant: "body2",
                              style: {
                                fontWeight: name === notebookName ? "bolder" : "normal",
                                marginLeft: "4px"
                              }
                            }), React.createElement(Core.Box, {
                              style: {
                                backgroundColor: theme.palette.background.paper,
                                position: "absolute",
                                right: "0",
                                top: "0",
                                visibility: match$2[0] !== undefined ? "visible" : "hidden",
                                zIndex: "45"
                              },
                              children: null
                            }, React.createElement(Core.Tooltip, {
                                  children: React.createElement(Core.Button, {
                                        onClick: handleClick,
                                        children: React.createElement(MoreHoriz, {
                                              fontSize: "small"
                                            }),
                                        color: darkMode ? "default" : "primary",
                                        size: "small"
                                      }),
                                  title: "More"
                                }), React.createElement(Core.Menu, {
                                  keepMounted: true,
                                  transformOrigin: transOri,
                                  anchorEl: anchorEl,
                                  children: null,
                                  MenuListProps: {
                                    dense: true,
                                    disablePadding: true
                                  },
                                  onClose: handleClose,
                                  open: Belt_Option.isSome(anchorEl),
                                  transitionDuration: 0.2,
                                  variant: "menu"
                                }, React.createElement(Core.MenuItem, {
                                      children: null,
                                      dense: true,
                                      onClick: (function (evt) {
                                          Curry._1(setAnchorEl, (function (_x) {
                                                  
                                                }));
                                          return FilesBase$Weblab.fileOpen(name, filesState.files, notebookDispatch);
                                        })
                                    }, React.createElement(Core.ListItemIcon, {
                                          children: React.createElement(OpenInNew, {
                                                fontSize: "small"
                                              })
                                        }), React.createElement(Core.ListItemText, {
                                          children: "Open"
                                        })), React.createElement(Core.MenuItem, {
                                      children: null,
                                      dense: true,
                                      onClick: (function (evt) {
                                          return Curry._1(setAnchorEl, (function (_x) {
                                                        
                                                      }));
                                        })
                                    }, React.createElement(Core.ListItemIcon, {
                                          children: React.createElement(SaveAlt, {
                                                fontSize: "small"
                                              })
                                        }), React.createElement(Core.ListItemText, {
                                          children: React.createElement("a", {
                                                style: {
                                                  color: theme.palette.text.primary,
                                                  fontSize: "14px",
                                                  textDecoration: "none"
                                                },
                                                download: name,
                                                href: "data:application/json," + encodeURIComponent(Belt_Result.getWithDefault(Belt_Result.map(FilesBase$Weblab.get(filesState.files, name), (function (file) {
                                                                return file._0;
                                                              })), ""))
                                              }, "Download")
                                        })), session !== undefined ? React.createElement(Core.MenuItem, {
                                        children: null,
                                        dense: true,
                                        onClick: (function (evt) {
                                            Curry._1(setAnchorEl, (function (_x) {
                                                    
                                                  }));
                                            HubBase$Weblab.shareFileOnNotebookHub(session, filesState.files, state.name).catch(function (error) {
                                                  Errors$Weblab.alertError({
                                                        TAG: /* Error */1,
                                                        _0: Errors$Weblab.fromPromiseError(error)
                                                      });
                                                  return Promise.resolve(undefined);
                                                });
                                            
                                          })
                                      }, React.createElement(Core.ListItemIcon, {
                                            children: React.createElement(CloudUpload, {
                                                  fontSize: "small"
                                                })
                                          }), React.createElement(Core.ListItemText, {
                                            children: "Share on NotebookHub"
                                          })) : null, React.createElement(Core.MenuItem, {
                                      children: null,
                                      dense: true,
                                      onClick: (function (evt) {
                                          Curry._1(setAnchorEl, (function (_x) {
                                                  
                                                }));
                                          return Curry._1(filesDispatch, {
                                                      TAG: /* DeleteFile */2,
                                                      _0: name
                                                    });
                                        })
                                    }, React.createElement(Core.ListItemIcon, {
                                          children: React.createElement(Delete, {
                                                fontSize: "small"
                                              })
                                        }), React.createElement(Core.ListItemText, {
                                          children: "Delete"
                                        })))))),
              style: {
                color: darkMode ? theme.palette.text.primary : theme.palette.primary.main
              }
            });
}

var make = FileItem;

export {
  transOri ,
  make ,
  
}
/* react Not a pure module */
