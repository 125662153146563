// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Theme$Weblab from "../service/Theme.bs.js";
import * as CellBase$Weblab from "./CellBase.bs.js";
import * as Core from "@material-ui/core";
import * as CodeMirror$Weblab from "../bindings/codemirror/CodeMirror.bs.js";
import HtmlReactParser from "html-react-parser";
import * as Webapi__Dom__Element from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as WeblabMarkdown$Weblab from "../bindings/WeblabMarkdown.bs.js";
import JsSvg from "/src/assets/svg/js.svg";
import * as Styles from "@material-ui/core/styles";
import Clear from "@material-ui/icons/Clear";
import * as WeblabInterpreter$Weblab from "../bindings/WeblabInterpreter.bs.js";
import Delete from "@material-ui/icons/Delete";
import Subject from "@material-ui/icons/Subject";
import PlayArrow from "@material-ui/icons/PlayArrow";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

function evalCell(cellState, cellDispatch, setSpinner, ref) {
  var match = cellState.cell_type;
  if (match) {
    return WeblabMarkdown$Weblab.parse(cellState.source.contents).then(function (output) {
                  Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(ref.current), (function (prim) {
                                                                      return Caml_option.nullable_to_opt(prim.nextElementSibling);
                                                                    })), (function (prim) {
                                                                  return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                                })), (function (prim) {
                                                              return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                            })), (function (prim) {
                                                          return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                        })), (function (prim) {
                                                      return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                    })), (function (prim) {
                                                  return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                })), (function (prim) {
                                              return Caml_option.nullable_to_opt(prim.firstElementChild);
                                            })), (function (prim) {
                                          return Caml_option.nullable_to_opt(prim.lastElementChild);
                                        })), (function (prim) {
                                      return Caml_option.nullable_to_opt(prim.firstElementChild);
                                    })), Webapi__Dom__Element.asHtmlElement), (function (element) {
                              element.focus();
                              
                            })), undefined);
                  return Promise.resolve(Curry._1(cellDispatch, {
                                  TAG: /* DisplayCellOutput */1,
                                  _0: [{
                                      TAG: /* TextPlain */0,
                                      _0: output
                                    }],
                                  _1: "none"
                                }));
                }).catch(function (param) {
                return Promise.resolve(Curry._1(cellDispatch, {
                                TAG: /* DisplayCellOutput */1,
                                _0: [{
                                    TAG: /* TextPlain */0,
                                    _0: "Could not parse markdown input"
                                  }],
                                _1: "inline-block"
                              }));
              });
  } else {
    Curry._1(setSpinner, (function (param) {
            return Caml_option.some(undefined);
          }));
    return WeblabInterpreter$Weblab.evalCell(cellState.source.contents).then(function (output) {
                  Curry._1(setSpinner, (function (param) {
                          
                        }));
                  Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(ref.current), (function (prim) {
                                                                      return Caml_option.nullable_to_opt(prim.nextElementSibling);
                                                                    })), (function (prim) {
                                                                  return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                                })), (function (prim) {
                                                              return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                            })), (function (prim) {
                                                          return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                        })), (function (prim) {
                                                      return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                    })), (function (prim) {
                                                  return Caml_option.nullable_to_opt(prim.firstElementChild);
                                                })), (function (prim) {
                                              return Caml_option.nullable_to_opt(prim.firstElementChild);
                                            })), (function (prim) {
                                          return Caml_option.nullable_to_opt(prim.lastElementChild);
                                        })), (function (prim) {
                                      return Caml_option.nullable_to_opt(prim.firstElementChild);
                                    })), Webapi__Dom__Element.asHtmlElement), (function (element) {
                              element.focus();
                              
                            })), undefined);
                  return Promise.resolve(Curry._1(cellDispatch, {
                                  TAG: /* DisplayCellOutput */1,
                                  _0: [output],
                                  _1: "inline-block"
                                }));
                }).catch(function (param) {
                return Promise.resolve(Curry._1(cellDispatch, {
                                TAG: /* DisplayCellOutput */1,
                                _0: [{
                                    TAG: /* TextPlain */0,
                                    _0: "Could not evaluate code input"
                                  }],
                                _1: "inline-block"
                              }));
              });
  }
}

function Cell(Props) {
  var cellState = Props.cellState;
  var notebookDispatch = Props.notebookDispatch;
  var selectedCell = Props.selectedCell;
  var match = React.useReducer(CellBase$Weblab.cellReducer, {
        cell_type: cellState.cell_type,
        source: cellState.source,
        outputs: cellState.outputs,
        display_input: cellState.display_input
      });
  var cellDispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setFocused = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setHover = match$2[1];
  var hover = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setSpinner = match$3[1];
  var theme = Styles.useTheme();
  var darkMode = Theme$Weblab.getMode(theme);
  var ref = React.useRef(null);
  var setRef = function (element) {
    ref.current = element;
    
  };
  var match$4 = cellState.cell_type;
  var tmp;
  var exit = 0;
  if (match$1[0] !== undefined || hover !== undefined && !match$4) {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement(Core.Box, {
          style: {
            bottom: "-16px",
            left: "16px",
            position: "absolute"
          },
          children: React.createElement(Core.ButtonGroup, {
                children: null,
                size: "small",
                style: {
                  backgroundColor: theme.palette.background.paper
                }
              }, React.createElement(Core.Tooltip, {
                    children: React.createElement(Core.Button, {
                          onMouseDown: (function (param) {
                              evalCell(cellState, cellDispatch, setSpinner, ref);
                              
                            }),
                          style: {
                            color: darkMode ? theme.palette.text.primary : theme.palette.primary.main
                          },
                          children: React.createElement(PlayArrow, {
                                fontSize: "small"
                              })
                        }),
                    title: "Run"
                  }), React.createElement(Core.Tooltip, {
                    children: React.createElement(Core.Button, {
                          onMouseDown: (function (param) {
                              return Curry._1(notebookDispatch, {
                                          TAG: /* MoveCellUp */3,
                                          _0: cellState.index
                                        });
                            }),
                          children: React.createElement(ArrowUpward, {
                                fontSize: "small"
                              }),
                          color: darkMode ? "default" : "primary"
                        }),
                    title: "Move cell up"
                  }), React.createElement(Core.Tooltip, {
                    children: React.createElement(Core.Button, {
                          onMouseDown: (function (param) {
                              return Curry._1(notebookDispatch, {
                                          TAG: /* MoveCellDown */4,
                                          _0: cellState.index
                                        });
                            }),
                          children: React.createElement(ArrowDownward, {
                                fontSize: "small"
                              }),
                          color: darkMode ? "default" : "primary"
                        }),
                    title: "Move cell down"
                  }), React.createElement(Core.Tooltip, {
                    children: React.createElement(Core.Button, {
                          onMouseDown: (function (param) {
                              return Curry._1(notebookDispatch, {
                                          TAG: /* ClearCodeOutput */8,
                                          _0: cellState.index
                                        });
                            }),
                          children: React.createElement(Clear, {
                                fontSize: "small"
                              }),
                          color: darkMode ? "default" : "primary"
                        }),
                    title: "Clear output"
                  }), React.createElement(Core.Tooltip, {
                    children: React.createElement(Core.Button, {
                          onMouseDown: (function (param) {
                              return Curry._1(notebookDispatch, {
                                          TAG: /* DeleteCell */2,
                                          _0: cellState.index
                                        });
                            }),
                          children: React.createElement(Delete, {
                                fontSize: "small"
                              }),
                          color: darkMode ? "default" : "primary"
                        }),
                    title: "Delete cell"
                  })),
          zIndex: 5
        });
  }
  var match$5 = cellState.cell_type;
  return React.createElement(Core.ListItem, {
              button: false,
              children: React.createElement("div", {
                    onMouseLeave: (function (param) {
                        return Curry._1(setHover, (function (param) {
                                      
                                    }));
                      }),
                    onMouseOver: (function (param) {
                        return Curry._1(setHover, (function (param) {
                                      return Caml_option.some(undefined);
                                    }));
                      })
                  }, React.createElement(Core.Box, {
                        style: {
                          position: "relative"
                        },
                        children: null
                      }, React.createElement(Core.TextField, {
                            style: {
                              backgroundColor: theme.palette.action.hover,
                              display: state.display_input.contents,
                              fontFamily: "Noto Mono"
                            },
                            color: darkMode ? "secondary" : "primary",
                            fullWidth: true,
                            InputProps: {
                              inputComponent: CodeMirror$Weblab.MyCodeMirror.make,
                              inputProps: {
                                component: CodeMirror$Weblab.make,
                                source: state.source,
                                cell_type: state.cell_type,
                                cellDispatch: cellDispatch,
                                onKeyDown: (function (evt) {
                                    if (evt.key === "Enter" && evt.shiftKey === true) {
                                      evt.preventDefault();
                                      evalCell(cellState, cellDispatch, setSpinner, ref);
                                      return ;
                                    }
                                    
                                  }),
                                onFocus: (function (_evt) {
                                    Curry._1(setFocused, (function (param) {
                                            return Caml_option.some(undefined);
                                          }));
                                    selectedCell.current = cellState.index;
                                    
                                  }),
                                onBlur: (function (_evt) {
                                    return Curry._1(setFocused, (function (param) {
                                                  
                                                }));
                                  }),
                                darkMode: darkMode ? true : false
                              }
                            },
                            margin: "dense",
                            multiline: true,
                            variant: "outlined"
                          }), tmp), match$5 ? [React.createElement("div", {
                            key: "div_" + String(cellState.index),
                            onDoubleClick: (function (evt) {
                                evt.preventDefault();
                                return Curry._1(cellDispatch, /* ResetCellOutput */0);
                              })
                          }, React.createElement(Core.Box, {
                                style: {
                                  fontFamily: "Noto Serif",
                                  fontSize: "16px",
                                  overflow: "inherit"
                                },
                                children: HtmlReactParser(CellBase$Weblab.outputToString(Caml_array.get(cellState.outputs.contents, 0)))
                              }))] : (
                      match$3[0] !== undefined ? React.createElement(Core.CircularProgress, {
                              size: 24,
                              style: {
                                margin: "8px"
                              }
                            }) : Belt_Array.mapWithIndex(cellState.outputs.contents, (function (i, output) {
                                var element = CellBase$Weblab.outputToReactElement(output);
                                if (element !== null) {
                                  return React.createElement(Core.Typography, {
                                              children: element,
                                              variant: "body1",
                                              style: {
                                                fontFamily: "Noto Mono",
                                                marginTop: "8px",
                                                maxHeight: "512px",
                                                overflow: "auto",
                                                padding: "8px",
                                                whiteSpace: "pre-wrap"
                                              },
                                              key: "output_" + String(cellState.index) + "_" + String(i)
                                            });
                                } else {
                                  return null;
                                }
                              }))
                    ), React.createElement(Core.Box, {
                        style: {
                          position: "relative"
                        },
                        children: null
                      }, React.createElement(Core.Divider, {
                            light: true,
                            style: {
                              opacity: hover !== undefined ? "1" : "0"
                            }
                          }), React.createElement(Core.Box, {
                            style: {
                              left: "0",
                              margin: "0 auto",
                              position: "absolute",
                              right: "0",
                              textAlign: "center",
                              top: "-12px",
                              visibility: hover !== undefined ? "visible" : "hidden",
                              width: "100%"
                            },
                            children: React.createElement(Core.ButtonGroup, {
                                  children: null,
                                  size: "small",
                                  style: {
                                    backgroundColor: theme.palette.background.paper
                                  }
                                }, React.createElement(Core.Tooltip, {
                                      children: React.createElement(Core.Button, {
                                            onMouseDown: (function (param) {
                                                return Curry._1(notebookDispatch, {
                                                            TAG: /* AddCodeCell */0,
                                                            _0: cellState.index
                                                          });
                                              }),
                                            children: React.createElement(JsSvg, {
                                                  className: "MuiSvgIcon-root",
                                                  fontSize: "small"
                                                }),
                                            color: darkMode ? "default" : "primary"
                                          }),
                                      title: "Add code cell"
                                    }), React.createElement(Core.Tooltip, {
                                      children: React.createElement(Core.Button, {
                                            onMouseDown: (function (param) {
                                                return Curry._1(notebookDispatch, {
                                                            TAG: /* AddMarkdownCell */1,
                                                            _0: cellState.index
                                                          });
                                              }),
                                            children: React.createElement(Subject, {
                                                  fontSize: "small"
                                                }),
                                            color: darkMode ? "default" : "primary"
                                          }),
                                      title: "Add markdown cell"
                                    })),
                            zIndex: 3
                          }))),
              dense: true,
              style: {
                display: "block"
              },
              key: "li_" + String(cellState.index),
              ref: setRef
            });
}

var make = Cell;

export {
  evalCell ,
  make ,
  
}
/* react Not a pure module */
